import React from 'react'
import { IoLogoGithub, IoLogoLinkedin, IoLogoInstagram } from 'react-icons/io5'
import { IconContext } from "react-icons"

function Footer() {

    return (
        <footer>
            <nav className="social-links">
                <IconContext.Provider value={{ color: '#CE93D8', size: '28px' }}>
                    <ul>
                        <li key={"github"}><a href="https://github.com/noelrmrz"><IoLogoGithub /></a></li>
                    </ul>
                    <ul>
                        <li key={"linkedin"}><a href="https://www.linkedin.com/in/noelrmrz/"><IoLogoLinkedin /></a></li>
                    </ul>
                    <ul>
                        <li key={"instagram"}><a href="https://www.instagram.com/metsu_hadouken/"><IoLogoInstagram /></a></li>
                    </ul>
                </IconContext.Provider>
            </nav>
        </footer>
    )
}

export default Footer