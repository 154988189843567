import './App.css'
import React,{} from 'react'
import Header from './functional_components/header'
import Footer from './functional_components/footer'
import Routes from './functional_components/routes'

function App() {

  return (
    <div className="App">
      <Header />
      <Routes />
      <Footer />
    </div>
  )
}

export default App