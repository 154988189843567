import { Switch, Route } from 'react-router-dom'
import React from 'react'
import About from './about'
import Body from './body'
import Article from './article'

function Routes() {

    return (
        <Switch>
            <Route exact path='/' component={Body} />
            <Route path='/about' component={About} />
            <Route path='/stuff' component={Body} />
            <Route path='/article' component={Article} />
        </Switch>
    )
}

export default Routes