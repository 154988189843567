import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDqqRDQeQXTyQ1TLvLGtQ_qDWNeVzBR7UQ",
    authDomain: "blog-8491b.firebaseapp.com",
    projectId: "blog-8491b",
    storageBucket: "blog-8491b.appspot.com",
    messagingSenderId: "940919427726",
    appId: "1:940919427726:web:9ddfa195f28b0ae750efdf",
    measurementId: "G-J5LQT73EC5"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app);

export default db;